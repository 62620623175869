import React from "react"

import Main from "../pages/main/index"
import Offers from "../pages/offers/index"

const publicRoutes = [
  { path: "/", component: Main },
  { path: "/offers", component: Offers }
]

export { publicRoutes }
