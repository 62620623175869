import React from "react"
import { Route } from "react-router-dom"

const Authmiddleware = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      return (
		<Component {...props} />
      )
    }}
  />
)

export default Authmiddleware;
