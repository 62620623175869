import React from 'react'
import Select from 'react-select'

import { Container, Row, Col, Label, Button, Card, CardBody } from 'reactstrap';
import logo from "../../assets/images/logo-regalosguru.png";

import * as ads from '../../components/iframes/Ads'
import * as offers from '../../components/iframes/Offers'
import Iframe from '../../components/iframes/IframeWrapper'

function Offers() {

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light static-top mb-5 shadow">
        <div className="container">
          <a href="/">
            <img src={logo} className="img-responsive" width="340px" alt="Regalos Gurú" />
          </a>
        </div>
      </nav>

      <Container>
        <Row className="justify-content-center">
          <div className="col-md-6">

            <Iframe iframe={ads.ads[0]}/>

            <Card className="border-0 shadow mt-2 px-5">
              <CardBody>

                <div className="mt-4">
                  <h1 className="ms-1">🎁</h1>
                  <h4 className="fw-normal">Te muestro varias opciones para regalar...</h4>
                  <hr></hr>
                </div>

                <div className="items-container align-items-start justify-content-between">
                  {offers.offers.map((offer, idx) => {
                    return (
                      <Iframe key={idx} iframe={offer}/>
                    )
                  })}
                </div>
              </CardBody>
            </Card>

          </div>

        </Row>

        <Row className="justify-content-center mb-4">
          <Col md={6}>
            <Card className="mt-3 border-0 shadow px-5 pt-3">
            <div className="d-flex justify-content-between">
              <h6>© {(new Date().getFullYear())} Copyright AI Labs</h6>
              <h6>hello@regalos.guru</h6>
            </div>

            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Offers;
