export const personAges = [
  {
    label: "Niños o Niñas",
    options: [
      { value: "newborn", label: "Recien Nacido" },
      { value: "1-3", label: "1-3 años" },
      { value: "4-7", label: "4-7 años" },
      { value: "8-11", label: "8-11 años" },
      { value: "12-14", label: "12-14 años" }
    ]
  },
  {
    label: "Jovenes",
    options: [
      { value: "15-17", label: "15-17 años" },
      { value: "18-22", label: "18-22 años" },
      { value: "23-24", label: "23-24 años" }
    ]
  },
  {
    label: "Adultos",
    options: [
      { value: "25-30", label: "25-30 años" },
      { value: "31-35", label: "31-35 años" },
      { value: "36-40", label: "36-40 años" },
      { value: "41-45", label: "41-45 años" },
      { value: "45-55", label: "45-55 años" },
      { value: "56-64", label: "56-64 años" },
      { value: "over65", label: "Mayor de 65 años" }
    ]
  }
];

export const personGenders = [
  { value: 'female', label: 'Femenino' },
  { value: 'male', label: 'Masculino' }
]

export const personRelationshipMale = [
  { value: 'boyfriend', label: 'Tu Novio' },
  { value: 'friend', label: 'Tu Amigo' },
  { value: 'father', label: 'Tu Papá' },
  { value: 'son', label: 'Tu Hijo' },
  { value: 'nephew', label: 'Tu Sobrino' },
  { value: 'brother', label: 'Tu Hermano' },
  { value: 'uncle', label: 'Tu Tio' }
]

export const personRelationshipFemale = [
  { value: 'girlfriend', label: 'Tu Novia' },
  { value: 'friend', label: 'Tu Amiga' },
  { value: 'daughter', label: 'Tu Hija' },
  { value: 'niece', label: 'Tu Sobrina' },
  { value: 'mother', label: 'Tu Mamá' },
  { value: 'sister', label: 'Tu Hermana' },
  { value: 'aunt', label: 'Tu Tía' }
]

export const personInterestsChilds = [
  { value: 'toys', label: 'Juguetes' },
  { value: 'books', label: 'Libros' },
  { value: 'sports', label: 'Deportes' },
  { value: 'arts', label: 'Arte' },
  { value: 'technology', label: 'Tecnología' },
  { value: 'pets', label: 'Animales y Mascotas' },
  { value: 'science', label: 'Ciencia' },
  { value: 'music', label: 'Musica' },
  { value: 'nature', label: 'Naturaleza' }
]

export const personInterestsYoungs = [
  { value: 'gadgets', label: 'Accesorios para dispositivos electrónicos' },
  { value: 'fashion', label: 'Ropa' },
  { value: 'videogames', label: 'Juegos de video' },
  { value: 'music', label: 'Musica' },
  { value: 'pets', label: 'Animales y Mascotas' },
  { value: 'nature', label: 'Naturaleza' }
]

export const personInterestsAdults = [
  { value: 'sports', label: 'Deportes' },
  { value: 'travelsAndAdventures', label: 'Viajes y Aventura' },
  { value: 'music', label: 'Música' },
  { value: 'technology', label: 'Tecnología' },
  { value: 'artsAndDesign', label: 'Arte y Diseño' },
  { value: 'scienceAndNature', label: 'Ciencia y Naturaleza' },
  { value: 'foodAndKitchen', label: 'Cocina y Comida' },
  { value: 'readingAndWriting', label: 'Lectura y Escritura' },
  { value: 'pets', label: 'Animales y Mascotas' },
  { value: 'gardeningAndCrafting', label: 'Jardinería y bricolaje' },
  { value: 'fitnessAndWellness', label: 'Fitness y bienestar' },
  { value: 'leisure', label: 'Ocio y Entretenimiento' }
]

export const personalityTypesChilds = [
  { value: 'practical', label: 'Práctico' },
  { value: 'adventurer', label: 'Aventurero' },
  { value: 'creative', label: 'Creativo' },
  { value: 'relaxed', label: 'Relajado' },
  { value: 'technologic', label: 'Tecnológico' },
  { value: 'environmentalist', label: 'Ambientalista' },
  { value: 'cultural', label: 'Cultural' },
  { value: 'social', label: 'Social' },
  { value: 'sporty', label: 'Deportivo' },
  { value: 'sentimental', label: 'Sentimental' }
]

export const personalityTypesYoungs = [
  { value: 'practical', label: 'Práctico' },
  { value: 'adventurer', label: 'Aventurero' },
  { value: 'creative', label: 'Creativo' },
  { value: 'relaxed', label: 'Relajado' },
  { value: 'technologic', label: 'Tecnológico' },
  { value: 'environmentalist', label: 'Ambientalista' },
  { value: 'cultural', label: 'Cultural' },
  { value: 'social', label: 'Social' },
  { value: 'sporty', label: 'Deportivo' },
  { value: 'sentimental', label: 'Sentimental' }
]

export const personalityTypesAdults = [
  { value: 'practical', label: 'Práctico' },
  { value: 'adventurer', label: 'Aventurero' },
  { value: 'creative', label: 'Creativo' },
  { value: 'relaxed', label: 'Relajado' },
  { value: 'technologic', label: 'Tecnológico' },
  { value: 'environmentalist', label: 'Ambientalista' },
  { value: 'cultural', label: 'Cultural' },
  { value: 'social', label: 'Social' },
  { value: 'sporty', label: 'Deportivo' },
  { value: 'sentimental', label: 'Sentimental' }
]

export const budget = [
  { value: '15-25', label: 'Entre 15 y 25 dólares' },
  { value: '25-50', label: 'Entre 25 y 50 dólares' },
  { value: '50-100', label: 'Entre 50 y 100 dólares' },
  { value: '100-200', label: 'Entre 100 y 200 dólares' }
]
