
function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
      className="d-flex justify-content-center"
    />
  );
}

export default Iframe;