import React from "react"
import { Switch, BrowserRouter as Router } from "react-router-dom"

import { publicRoutes } from "./routes"
import Authmiddleware from "./routes/route"

import "./App.css"

const App = props => {

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              component={route.component}
              key={idx}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  )
}


export default App
