import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import { useHistory } from "react-router-dom";

import { useForm, Controller } from 'react-hook-form';

import { Container, Row, Col, Label, Button, Card, CardBody, Form } from 'reactstrap';
import logo from "../../assets/images/logo-regalosguru.png";

import * as listOptions from './selectOptions'

import * as ads from '../../components/iframes/Ads'
import Iframe from '../../components/iframes/IframeWrapper'

const _ = require('lodash');

const isChild = ["newborn", "1-3", "4-7", "8-11", "12-14"];
const isYoung = ["15-17", "18-22", "23-24"];
const isAdult = ["18-22", "23-24", "25-30", "31-35", "36-40", "41-45", "45-55", "56-64", "over65"];

const App = () => {

  const [filteredPersonRelationshipMale, setFilteredPersonRelationshipMale] = useState([]);
  const [filteredPersonRelationshipFemale, setFilteredPersonRelationshipFemale] = useState([]);

  const [filteredPersonInterests, setFilteredPersonInterests] = useState([]);
  const [filteredPersonality, setFilteredPersonality] = useState([]);

  const [selectedAge, setSelectedAge] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedRelationship, setSelectedRelationship] = useState(null);
  const [selectedInterests, setSelectedInterests] = useState(null);
  const [selectedPersonality, setSelectedPersonality] = useState(null);
  const [selectedBudget, setSelectedBudget] = useState(null);

  const history = useHistory();

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isSubmitting, isDirty, isValid, errors } // here
  } = useForm({ mode: "onSubmit" })

  const handleSelectedAge = (selectedOption) => {

    setSelectedAge(selectedOption);

    setFilteredPersonRelationshipMale(listOptions.personRelationshipMale)
    setFilteredPersonRelationshipFemale(listOptions.personRelationshipFemale)

    console.log(selectedOption)

    if (isChild.indexOf(selectedOption.value) > -1) {
      setFilteredPersonInterests(listOptions.personInterestsChilds)
      setFilteredPersonality(listOptions.personalityTypesChilds)
    } else if (isYoung.indexOf(selectedOption.value) > -1) {
      setFilteredPersonInterests(listOptions.personInterestsYoungs)
      setFilteredPersonality(listOptions.personalityTypesYoungs)
    } else if (isAdult.indexOf(selectedOption.value) > -1) {
      setFilteredPersonInterests(listOptions.personInterestsAdults)
      setFilteredPersonality(listOptions.personalityTypesAdults)
    }
  }

  const handleSelectedGender = (selectedOption) => {

    console.log(selectedAge.value)
    console.log(selectedOption.value)

    setSelectedGender(selectedOption);

    const forbiddenForChild = ["boyfriend", "girlfriend", "father", "mother", "uncle", "aunt"]
    const forbiddenForYoung = ["father", "mother", "uncle", "aunt"]

    if (isChild.indexOf(selectedAge.value) > -1) {

      if (selectedOption.value == 'male') {

        let newObj = listOptions.personRelationshipMale.filter(obj => forbiddenForChild.indexOf(obj.value) != -1);

        let filteredArray = [].concat(
            listOptions.personRelationshipMale.filter(obj1 => newObj.every(obj2 => obj1.value !== obj2.value)),
            newObj.filter(obj2 => listOptions.personRelationshipMale.every(obj1 => obj2.value !== obj1.value))
        );

        setFilteredPersonRelationshipMale(filteredArray)
      } else if (selectedOption.value == 'female') {

        let newObj = listOptions.personRelationshipFemale.filter(obj => forbiddenForChild.indexOf(obj.value) != -1);

        let filteredArray = [].concat(
            listOptions.personRelationshipFemale.filter(obj1 => newObj.every(obj2 => obj1.value !== obj2.value)),
            newObj.filter(obj2 => listOptions.personRelationshipFemale.every(obj1 => obj2.value !== obj1.value))
        );

        setFilteredPersonRelationshipFemale(filteredArray)
      }
    }

    if (isYoung.indexOf(selectedAge.value) > -1) {

      if (selectedOption.value == 'male') {

        let newObj = listOptions.personRelationshipMale.filter(obj => forbiddenForYoung.indexOf(obj.value) != -1);

        let filteredArray = [].concat(
            listOptions.personRelationshipMale.filter(obj1 => newObj.every(obj2 => obj1.value !== obj2.value)),
            newObj.filter(obj2 => listOptions.personRelationshipMale.every(obj1 => obj2.value !== obj1.value))
        );

        setFilteredPersonRelationshipMale(filteredArray)
      } else if (selectedOption.value == 'female') {

        let newObj = listOptions.personRelationshipFemale.filter(obj => forbiddenForYoung.indexOf(obj.value) != -1);

        let filteredArray = [].concat(
            listOptions.personRelationshipFemale.filter(obj1 => newObj.every(obj2 => obj1.value !== obj2.value)),
            newObj.filter(obj2 => listOptions.personRelationshipFemale.every(obj1 => obj2.value !== obj1.value))
        );

        setFilteredPersonRelationshipFemale(filteredArray)
      }
    }

  }

  const handleSelectedRelationship = (selectedOption) => {
    console.log(selectedOption)
    setSelectedRelationship(selectedOption);
  }

  const handleSelectedInterests = (selectedOption) => {
    console.log(selectedOption)
    setSelectedInterests(selectedOption);
  }

  const handleSelectedBudget = (selectedOption) => {
    console.log(selectedOption)
    setSelectedBudget(selectedOption);
  }



  const onSubmit = data => {

    console.log(data)
    history.push('/offers');
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light static-top mb-5 shadow">
        <div className="container">
          <a href="/">
            <img src={logo} className="img-responsive" width="340px" alt="Regalos Gurú" />
          </a>
        </div>
      </nav>

      <Container>
        <Row className="justify-content-center">
          <div className="col-md-6">

            <Iframe iframe={ads.ads[0]}/>

            <Card className="border-0 shadow mt-2 px-5">
              <CardBody>

                <div className="mt-4">
                  <h1 className="ms-1">🎁</h1>
                  <h2 className="fw-normal">¿No sabes que regalar?</h2>
                  <p className="fw-light mb-3">Dame esta informacion y nuestra inteligencia artificial te ayudará con recomendaciones.</p>
                </div>

                <Form className="g-3 align-items-center mt-4 border-bottom" onSubmit={handleSubmit(onSubmit)}>

                  <Row className="mt-4">
                    <Label><span>1️⃣</span> ¿Que edad tiene la persona?</Label>
                    <Controller
                      control={control}
                      name="personAge"
                      rules={{ required: `Este campo es requerido.` }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={listOptions.personAges}
                          onChange={(selectedOption) => {
                            onChange(selectedOption);
                            handleSelectedAge(selectedOption);
                          }}
                          value={selectedAge}
                          isSearchable={false}
                          placeholder={"Edad de la persona"}
                        />
                      )}
                    />
                    <span className="srv-validation-message"> {errors.personAge && errors.personAge.message}</span>
                  </Row>

                  <Row className="mt-4">
                    <Label><span>2️⃣</span> ¿Que genero tiene la persona?</Label>
                    <Controller
                      control={control}
                      name="personGender"
                      rules={{ required: `Este campo es requerido.` }}

                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={listOptions.personGenders}
                          onChange={(selectedOption) => {
                            onChange(selectedOption);
                            handleSelectedGender(selectedOption);
                          }}
                          value={selectedGender}
                          isSearchable={false}
                          placeholder={"Genero de la persona"}
                          isDisabled={!selectedAge}
                        />
                      )}
                    />
                    <span className="srv-validation-message"> {errors.personGender && errors.personGender.message}</span>
                  </Row>

                  <Row className="mt-4">
                    <Label><span>3️⃣</span> ¿Que relación tienes con la persona?</Label>
                    <Controller
                      control={control}
                      name="personRelationship"
                      rules={{ required: `Este campo es requerido.` }}

                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={selectedGender?.value == 'male' ? filteredPersonRelationshipMale : filteredPersonRelationshipFemale}
                          onChange={(selectedOption) => {
                            onChange(selectedOption);
                            handleSelectedRelationship(selectedOption);
                          }}
                          value={selectedRelationship}
                          isSearchable={false}
                          placeholder={"Relación con la persona"}
                          isDisabled={!selectedAge}
                        />
                      )}
                    />
                    <span className="srv-validation-message"> {errors.personRelationship && errors.personRelationship.message}</span>
                  </Row>

                  <Row className="mt-4">
                    <Label><span>4️⃣</span> ¿Que intereses tiene la persona?</Label>
                    <Controller
                      control={control}
                      name="personInterests"
                      rules={{ required: `Este campo es requerido.` }}

                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={filteredPersonInterests}
                          onChange={(selectedOption) => {
                            onChange(selectedOption);
                            handleSelectedInterests(selectedOption);
                          }}
                          value={selectedInterests}
                          isSearchable={false}
                          placeholder={"Intereses de la persona"}
                          isDisabled={!selectedAge}
                        />
                      )}
                    />
                    <span className="srv-validation-message"> {errors.personInterests && errors.personInterests.message}</span>
                  </Row>

                  {/*
                    <Row className="mt-4">
                      <Label><span>5️⃣</span> ¿Cual es su personalidad?</Label>
                      <Select
                        placeholder="Personalidad"
                        options={filteredPersonality}
                        isSearchable={false}
                      />
                    </Row>
                  */}

                  <Row className="mt-4">
                    <Label><span>6️⃣</span> ¿Cual es tu presupuesto?</Label>
                    <Controller
                      control={control}
                      name="budget"
                      rules={{ required: `Este campo es requerido.` }}

                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={listOptions.budget}
                          onChange={(selectedOption) => {
                            onChange(selectedOption);
                            handleSelectedBudget(selectedOption);
                          }}
                          value={selectedBudget}
                          isSearchable={false}
                          placeholder={"Presupuesto para el regalo"}
                          isDisabled={!selectedAge}
                        />
                      )}
                    />
                    <span className="srv-validation-message"> {errors.budget && errors.budget.message}</span>
                  </Row>

                  <Row className="mt-4">
                    <Button type="submit"
                    className="btn btn-lg btn-block btn-brand"
                    >Buscar Regalo</Button>
                  </Row>
                </Form>
              </CardBody>

            </Card>

          </div>

        </Row>
        <Row className="justify-content-center">
          <Col md={6}>
            <Card className="mt-3 border-0 shadow px-5 pt-3">
            <div className="d-flex justify-content-between">
              <h6>© {(new Date().getFullYear())} Copyright AI Labs</h6>
              <h6>hello@regalos.guru</h6>
            </div>

            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App;
